export function vues(){
	
	const site_vue = new Vue({
		el: '#site-app',
		data: {
			// default
			company_name: '株式会社Webkid',
			user_name: '田中太郎',
			
			// schedule
			schedule_window: false,
			
			// memo
			memo_window: false,
			
			// message
			message_text: '',
			message_flag: false,
			
			// holiday
			holiday: []
			
		},
		methods: {	
			// schedule
			window_toggle: function(){
				this.schedule_window = !this.schedule_window;
			},
			
			// memo
			memo_toggle: function(){
				this.memo_window = !this.memo_window;
			},
			
			// message view
			message: function(str){
				if(!this.message_flag){
					this.message_flag = true;
					this.message_text = str;
				}
                setTimeout(function(){
                    site_vue._data.message_flag = false;
                },2000);
			}
		},
		created() {
            axios
            .get('https://holidays-jp.github.io/api/v1/date.json')
            .then(function (res) {
				
				const resdata = res.data;
				const today = new Date();
				const regexp = new RegExp(today.getFullYear());

				for (let key in resdata) {
					if (resdata.hasOwnProperty(key)) {
						if(!key.match(regexp)){
							delete resdata[key];
						}
					}
				}
				
				site_vue._data.holiday = resdata;

            })
            .catch(function (err) {
                console.log(err);
            });
		},
	});
}